<template>
  <div :style="{ 'min-width': minWidth, width: width }" class="inputs">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :style="{
            height: height,
            color: colors.select_btn_color,
            background: colors.select_btn_bg,
          }"
          class="input-select-part"
          dark
          v-bind="attrs"
          v-on="on"
          >Field(s) <v-icon right>mdi-chevron-down</v-icon></v-btn
        >
      </template>
      <v-list :color="colors.select_menu_bg" :style="{color: colors.select_menu_color}">
        <v-list-item @click="selectField(item)" link v-for="item in field_items" v-bind:key="item">
          <v-list-item-title>{{ item }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-text-field
      v-model="input"
      dense
      :height="height"
      :style="{
        height: height,
        height,
        color: colors.input_color,
        background: colors.input_bg_color,
      }"
      outlined
      flat
      class="input-part"
    ></v-text-field>
    <v-btn
      @click="submitField()"
      :style="{
        height: height,
        color: colors.select_btn_color,
        background: colors.select_btn_bg,
      }"
      class="input-btn-part"
      >Search</v-btn
    >
  </div>
</template>

<script>
export default {
  name: "CombinedInput",
  props: {
    height: {
      type: String,
      default: "50px",
    },

    width: {
      type: String,
      default: "700px",
    },

    minWidth: {
      type: String,
      default: "400px",
    },

    colors: {
      type: Object,
      default: () => {
        return {
          select_btn_bg: "#4d73ff",
          select_btn_color: "#ffffff",
          select_menu_bg: "#172538",
          select_menu_color: '#ffffff',
          input_color: "#ffffff",
          input_bg_color: "#1b2e4b",
        };
      },
    },
  },

  methods: {
    selectField(field) {
        this.input = field.toLowerCase() + ':';
        this.currentField = field.toLowerCase();
    },

    submitField() {
        if(this.currentField != '') {
            this.$emit('submitted', this.input);
            return
        }
        
        this.$emit('submitted', this.input);
    }
  },

  data() {
    return {
      input: "",
      currentField: '',
      field_items: [
        "All Fields (Default)",
        "Email",
        "Username",
        "IP",
        "Name",
        "Address",
        "Phone",
        "VIN",
        "Domain Scan",
      ],
    };
  },
};
</script>

<style lang="scss">
.inputs {
  display: flex;
  flex-direction: row;
  

  .input-part {
    border-radius: 0 !important;

    input {
        font-family: "Poppins";
        font-size:15px;
        letter-spacing: 1px;
    }

    fieldset {
      outline: none !important;
      border: none !important;
    }
  }

  .input-select-part {
    height: 100%;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .input-btn-part {
    height: 100%;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
</style>