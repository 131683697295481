<template>
  <div>
    <SectionHeading
      style="z-index: 9999"
      :content="heading.content"
      :extra_content="heading.extra"
    />

    <div class="search-engine">
      <div
        v-if="!results_loaded"
        class="w-100 d-flex justify-center flex-column align-center"
      >
        <span class="search-heading d-flex justify-center flex-column">
          <span>Search in the biggest database collection</span>
          <span>Over <span>10,000,000,000</span> compromised assets</span>
        </span>
        <div class="search-input">
          <CombinedInput
            height="50px"
            width="900px"
            minWidth="350px"
            :colors="{
              select_btn_bg: 'var(--v-searchEngine_input_button_bgColor-base)',
              select_btn_color: 'var(--v-searchEngine_input_button_color-base)',
              select_menu_bg: 'var(--v-searchEngine_select_menu_bgColor-base)',
              select_menu_color: 'var(--v-searchEngine_select_menu_color-base',
              input_color: 'var(--searchEngine_input_color-base)',
              input_bg_color: 'var(--v-searchEngine_input_bgColor-base)',
            }"
            style="max-width: 800px"
            @submitted="searchSubmitted"
          />
        </div>

        <span class="text--h5 mt-10 text-center"
          >If you see your data here, please, report it to us via the support
          section to get it removed ASAP!</span
        >
      </div>

      <div
        class="
          results-overview
          d-flex
          flex-column
          justify-center
          align-center
          mt-4
        "
      >
        <v-progress-circular
          :size="70"
          :width="7"
          class="result-progress mt-15"
          v-if="search_loading"
          indeterminate
        ></v-progress-circular>

        <transition name="slide-from-bottom">
          <div class="w-100" v-if="results_loaded">
            <div class="d-flex justify-center align-center flex-row">
              <v-btn
                @click="results_loaded = false"
                style="background: none"
                elevation="0"
              >
                Search Again
              </v-btn>
            </div>
            <v-card class="search-results-count mt-5" elevation="3">
              <v-card-text>
                Your search was completed in 0.062 seconds with
                {{ results.length }} results.
              </v-card-text>
            </v-card>

            <div class="results w-100 mt-2">
              <v-expansion-panels class="results-container">
                <v-expansion-panel v-for="(db, i) in results" :key="i">
                  <v-expansion-panel-header>
                    {{ db.name.toUpperCase() }}

                    <template v-slot:actions>
                      <div class="d-flex align-center">
                        {{ db.rows.length }} result(s)
                        <v-icon> mdi-chevron-down </v-icon>
                      </div>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-data-table
                      :headers="combineFields(db)"
                      :items="agroItems(db)"
                      :items-per-page="10"
                      ><template v-slot:item.action="">
                        <v-icon small class="mr-4"> mdi-content-copy </v-icon>

                        <v-icon small class="mr-2" color="#E74C3C">
                          mdi-alert
                        </v-icon>
                      </template></v-data-table
                    >
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import SectionHeading from "../components/SectionHeading.vue";
import CombinedInput from "../components/CombinedInput.vue";
export default {
  name: "Search",

  components: { SectionHeading, CombinedInput },

  methods: {
    combineFields(db) {
      var fields = [];
      for (var row of db.rows) {
        fields = fields.concat(row.fields);
      }

      var uniq = (value, index, self) => {
        return self.indexOf(value) === index;
      };

      fields = fields.filter(uniq);

      return fields.map((field) => {
        return { text: field.toUpperCase(), value: field };
      });
    },

    agroItems(db) {
      var data = [];
      var fields = this.combineFields(db);

      for (var i = 0; i < db.rows.length; i++) {
        data[i] = {};
        for (var ix = 0; ix < fields.length; ix++) {
          data[i][fields[ix]["value"]] = db.rows[i].data[fields[ix]["value"]]
            ? db.rows[i].data[fields[ix]["value"]]
            : "Remove Request";
        }
      }

      return data;
    },

    async searchSubmitted(val) {
      this.search_loading = true;

      const searchValue = val.split(":");
      const isStructured = searchValue.length > 1;

      var fields = {};

      if (isStructured) fields[searchValue[0]] = searchValue[1];

      const res = await this.$http
        .post("/public/query", {
          captcha: "",
          structuredLookup: isStructured,
          fields: fields,
          rawQuery: !isStructured ? searchValue : "",
        })
        .then((res) => res.data.data);

      this.results = [];

      for (var db of res) {
        var objAdd = {};
        objAdd.name = db.database_name;
        objAdd.leak_date = db.leak_date;
        objAdd.rows = [];
        objAdd.rows.push({ fields: db.fields, data: db.data });
        this.results.push(objAdd);
      }

      setTimeout(() => {
        this.search_loading = false;
        this.results_loaded = true;
      }, 1000);
    },
  },

  computed: {},

  data: () => ({
    heading: {
      content: "Database Search Engine",
      extra: [{ type: "text", text: "Active and Ready", color: "#2ECC71" }],
    },

    secondary_heading: {
      content: "Your Data is Important",
      extra:
        "If you see your data here, please, contact us via the support section to get it removed..",
    },

    search_loading: false,
    results_loaded: false,

    results: [],

    database_headers: [
      { text: "Username", value: "username" },
      { text: "Email", value: "email" },
      { text: "IP", value: "ip" },
      { text: "", value: "action" },
    ],

    database_data: [
      {
        username: "dankatabg",
        email: "gucci@mane.bg",
        ip: "999.999.999.999",
      },
    ],
  }),
};
</script>

<style lang="scss">
.results-overview {
  display: flex;
  max-width: 900px;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  width: 100%;

  .results-container {
    .v-data-table {
      background: none !important;

      .v-data-table__wrapper > table > tbody > tr:hover {
        background: none !important;
      }
    }

    .v-expansion-panel {
      background: var(--v-result_bgColor-base) !important;
      font-family: "Poppins";

      button {
        font-size: 14px !important;
      }
    }
  }

  .search-results-count {
    width: 100%;
    background: var(--v-searchEngine_input_bgColor-base) !important;

    .v-card__text {
      text-align: center;
      font-family: "Poppins";
    }
  }

  .result-progress {
    color: var(--v-section_font_color-base) !important;
  }
}

.search-engine {
  padding: 30px 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .search-input {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 60px;
    width: 100%;
  }

  .search-heading {
    text-align: center;
    font-size: 26px;
    font-family: "Poppins";
    font-weight: 600;
    min-width: 350px;
    padding: 30px 0;

    span:last-of-type {
      font-size: 16px;
      font-family: "Poppins";
      text-transform: uppercase;
      font-weight: 300;
      margin-top: 10px;
      color: var(--v-section_font_color-base);

      span {
        color: #e74c3c;
      }
    }
  }
}
</style>

